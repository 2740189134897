export const CROSSWORD = [
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 1, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 2, question: 0 },
    { key: 'e', question: 2 },
    { key: 'q', question: 2 },
    { key: 'u', question: 2 },
    { key: 'i', question: 2 },
    { key: 'p', question: 2 },
    { key: 'm', question: 2 },
    { key: 'e', question: 2 },
    { key: 'n', question: 2 },
    { key: 't', question: 2 },
    { key: 's', question: 2 },
    { key: 'c', question: [1, 2] },
    { key: 'r', question: 2 },
    { key: 'e', question: 2 },
    { key: 'e', question: 2 },
    { key: 'n', question: 2 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'a', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 5, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 7, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'n', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 4, question: 0 },
    { key: 0, question: 0 },
    { key: 'g', question: 5 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 6, question: 0 },
    { key: 0, question: 0 },
    { key: 'p', question: 7 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'd', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'b', question: 4 },
    { key: 0, question: 0 },
    { key: 'r', question: 5 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 8, question: 0 },
    { key: 0, question: 0 },
    { key: 't', question: 6 },
    { key: 0, question: 0 },
    { key: 'l', question: 7 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'y', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 9, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'p', question: 4 },
    { key: 0, question: 0 },
    { key: 'i', question: 5 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'r', question: 8 },
    { key: 0, question: 0 },
    { key: 'r', question: 6 },
    { key: 0, question: 0 },
    { key: 'a', question: 7 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'c', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 3, question: 0 },
    { key: 'c', question: [3, 9] },
    { key: 'l', question: 3 },
    { key: 'a', question: 3 },
    { key: 'i', question: 3 },
    { key: 'm', question: 3 },
    { key: 'm', question: [3, 4] },
    { key: 'a', question: 3 },
    { key: 'n', question: [3, 5] },
    { key: 'a', question: 3 },
    { key: 'g', question: 3 },
    { key: 'e', question: [3, 8] },
    { key: 'm', question: 3 },
    { key: 'e', question: 3 },
    { key: 'n', question: 3 },
    { key: 't', question: [3, 7] },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'a', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 'p', question: 9 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'c', question: 5 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'd', question: 8 },
    { key: 0, question: 0 },
    { key: 'e', question: 6 },
    { key: 0, question: 0 },
    { key: 'f', question: 7 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'n', question: 1 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 'q', question: 9 },
    { key: 0, question: 0 },
    { key: 10, question: 0 },
    { key: 'p', question: 10 },
    { key: 'a', question: 10 },
    { key: 'l', question: 10 },
    { key: 's', question: 10 },
    { key: 'h', question: [5, 10] },
    { key: 'o', question: 10 },
    { key: 'w', question: 10 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 11, question: 0 },
    { key: 'n', question: 11 },
    { key: 'o', question: [7, 11] },
    { key: 'r', question: 11 },
    { key: 't', question: 11 },
    { key: 'h', question: 11 },
    { key: 'p', question: 11 },
    { key: 'o', question: 11 },
    { key: 'l', question: 11 },
    { key: 'e', question: [1, 11] },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 'r', question: 7 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
  [
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 12, question: 0 },
    { key: 'f', question: 12 },
    { key: 'l', question: 12 },
    { key: 'e', question: 12 },
    { key: 'e', question: 12 },
    { key: 't', question: 12 },
    { key: 'm', question: [7, 12] },
    { key: 'o', question: 12 },
    { key: 'n', question: 12 },
    { key: 'i', question: 12 },
    { key: 't', question: 12 },
    { key: 'o', question: 12 },
    { key: 'r', question: 12 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
    { key: 0, question: 0 },
  ],
];
