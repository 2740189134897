<div fxLayout="column" class="container">
  <div fxFlex="0 0 auto">
    <ng-content select="ds-full-page-header"> </ng-content>
  </div>
  <div fxFlex="1 1 auto" fxLayout="column" class="content-wrapper">
    <ng-content></ng-content>
    <div
      class="content no-min-height"
      [@dsStepTransition]="routerStepState"
      (@dsStepTransition.done)="animationDone.next($event)"
      *ngIf="pageBody"
      fxFlex="1 1 auto"
      fxLayout="column"
    >
      <ng-content select="ds-full-page-body"> </ng-content>
    </div>
  </div>
  <div fxFlex="0 0 auto" class="footer-container">
    <div fxLayout="row" fxLayoutAlign="start cetner">
      <button
        *ngIf="pageFooter?.showFreshDeskWidget"
        mat-icon-button
        type="button"
        color="accent"
        (click)="openFreshdeskWidget()"
        fxFlex="0 0 auto"
      >
        <mat-icon>forum</mat-icon>
      </button>
      <div fxFlex="1 1 auto">
        <ng-content select="ds-full-page-footer"> </ng-content>
      </div>
    </div>
  </div>
</div>
