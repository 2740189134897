import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DsDrawerState } from '../store/drawer.reducer';
import { DsDrawerSelectors } from '../store/drawer.selectors';

@Component({
  selector: 'ds-drawer-sticky-header',
  templateUrl: './drawer-sticky-header.component.html',
  styleUrls: ['./drawer-sticky-header.component.scss'],
  standalone: false,
})
export class DrawerStickyHeaderComponent {
  stickyOffset$: Observable<number>;

  constructor(private _store: Store<DsDrawerState>) {
    this.stickyOffset$ = this._store.select(DsDrawerSelectors.stickyOffset);
  }
}
