import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { StoreModule } from '@ngrx/store';
import { DrawerStickyHeaderComponent } from './drawer-sticky-header/drawer-sticky-header.component';
import {
  DsDrawerComponent,
  DsDrawerContentComponent,
  DsDrawerSidebarDirective,
} from './drawer.component';
import { DsDrawerState, drawerReducer } from './store/drawer.reducer';

export interface DrawerStoreFeature {
  drawer: DsDrawerState;
}

export const drawerFeature = {
  drawer: drawerReducer,
};

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    StoreModule.forFeature('drawerFeature', { drawer: drawerReducer }),
    ObserversModule,
  ],
  declarations: [
    DsDrawerComponent,
    DsDrawerContentComponent,
    DsDrawerSidebarDirective,
    DrawerStickyHeaderComponent,
  ],
  exports: [
    DsDrawerComponent,
    DsDrawerContentComponent,
    DsDrawerSidebarDirective,
    DrawerStickyHeaderComponent,
  ],
})
export class DsDrawerModule {}
