/* eslint-disable no-prototype-builtins */
import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CROSSWORD } from './crossword';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { XmasDialogComponent } from './dialog/dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DsSpacingModule } from '@design-system/cdk/spacing';

@Component({
  selector: 'ds-docu-xmas',
  templateUrl: './xmas.component.html',
  styleUrls: ['./xmas.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    DsEquipmentIconModule,
    FlexLayoutModule,
    DsSpacingModule,
  ],
})
export class XmasComponent {
  @ViewChildren('input') inputFields!: QueryList<ElementRef>;
  crosswordLayout = CROSSWORD;
  allQuestions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  crosswordForm: FormGroup;

  beforeState;
  solvedQuestions: number[] = [];
  prevQuestion: number | null = null;
  prevField: string | null = null;

  constructor(private _dialog: MatDialog) {
    const formControls: any = {};

    // Initialize the crossword form controls based on the layout
    this.crosswordLayout.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        formControls[`cell_${rowIndex}_${colIndex}`] = new FormControl(
          {
            value: typeof cell.key === 'number' && cell.key ? cell.key : '',
            disabled: !cell.key || typeof cell.key === 'number',
          },
          {
            validators:
              !cell.key || typeof cell.key === 'number'
                ? []
                : [
                    Validators.required,
                    Validators.pattern(/[A-Za-z]/),
                    Validators.maxLength(1),
                    this.equalToValidator(cell.key),
                  ],
          },
        );
      });
    });

    this.crosswordForm = new FormGroup(formControls);
    this.beforeState = this.crosswordForm.value;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.crosswordForm.valueChanges.subscribe((value) => {
      const changes = this.deepCompare(this.beforeState, value);
      this.beforeState = value;
      let focusField: string | null = null;
      // eslint-disable-next-line guard-for-in
      for (const key in changes) {
        if (changes[key].obj2 === '') {
          return;
        } else {
          const rowIndex = Number(key.split('_')[1]);
          const colIndex = Number(key.split('_')[2]);

          const questions = this.crosswordLayout[rowIndex][colIndex].question;
          let question: number | null = null;

          if (typeof questions === 'number') {
            question = questions;
          } else if (
            this.prevQuestion &&
            questions.includes(this.prevQuestion)
          ) {
            question = this.prevQuestion;
          } else if (
            this.prevQuestion &&
            this.solvedQuestions.includes(this.prevQuestion)
          ) {
            question =
              questions.find((q) => !this.solvedQuestions.includes(q)) || null;
          }
          if (!question) return;

          if (this.isQuestionSolved(question)) return;
          this.prevQuestion = question;

          const nextField = this.findNextField(rowIndex, colIndex, question);
          if (!nextField) return;

          this.prevField = `cell_${rowIndex}_${colIndex}`;
          focusField = `cell_${nextField.rowIndex}_${nextField.colIndex}`;

          if (!focusField) return;

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.inputFields
            ?.find(
              (inputField) =>
                inputField.nativeElement.getAttribute('field') === focusField,
            )
            .nativeElement.focus();
        }
      }
    });
  }

  deepCompare(obj1: any, obj2: any, path: string[] = []): Record<string, any> {
    const differences: Record<string, any> = {};

    const isObject = (val: any) =>
      val && typeof val === 'object' && !Array.isArray(val);

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const fullPath = [...path, key].join('.');
        if (!obj2.hasOwnProperty(key)) {
          differences[fullPath] = { obj1: obj1[key], obj2: undefined };
        } else if (isObject(obj1[key]) && isObject(obj2[key])) {
          Object.assign(
            differences,
            this.deepCompare(obj1[key], obj2[key], [...path, key]),
          );
        } else if (obj1[key] !== obj2[key]) {
          differences[fullPath] = { obj1: obj1[key], obj2: obj2[key] };
        }
      }
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        const fullPath = [...path, key].join('.');
        differences[fullPath] = { obj1: undefined, obj2: obj2[key] };
      }
    }

    return differences;
  }

  findNextField(rowIndex: number, colIndex: number, question: number) {
    let nextField: any = null;

    for (let i = rowIndex; i < this.crosswordLayout.length; i++) {
      for (
        let j = i === rowIndex ? colIndex + 1 : 0;
        j < this.crosswordLayout[i].length;
        j++
      ) {
        if (
          Array.isArray(this.crosswordLayout[i][j].question)
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.crosswordLayout[i][j].question.includes(question)
            : this.crosswordLayout[i][j].question === question
        ) {
          nextField = { rowIndex: i, colIndex: j };
          break;
        }
      }

      if (nextField) {
        break;
      }
    }

    return nextField;
  }

  isQuestionSolved(question: number): boolean {
    const fieldRelatedToQuestion = this.findFieldRelatedToQuestion(question);

    const isQuestionSolved = fieldRelatedToQuestion.every(
      (field) =>
        this.crosswordForm.get(`cell_${field.rowIndex}_${field.colIndex}`)
          ?.valid,
    );
    if (isQuestionSolved) {
      this.solvedQuestions.push(question);
      this.prevQuestion = question++;
    }
    if (this.solvedQuestions.length === 12) {
      this.openWinForm();
    }
    return isQuestionSolved;
  }

  equalToValidator(expectedValue: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value.toLowerCase() === expectedValue.toLowerCase()) {
        return null; // Valid: value matches expected value
      }
      return { equalTo: { valid: false } }; // Invalid: value does not match expected value
    };
  }

  findFieldRelatedToQuestion(question: number) {
    const fieldRelatedToQuestion: any = [];
    for (let i = 0; i < this.crosswordLayout.length; i++) {
      for (let j = 0; j < this.crosswordLayout[i].length; j++) {
        if (
          Array.isArray(this.crosswordLayout[i][j].question)
            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.crosswordLayout[i][j].question.includes(question)
            : this.crosswordLayout[i][j].question === question
        ) {
          fieldRelatedToQuestion.push({ rowIndex: i, colIndex: j });
        }
      }
    }
    return fieldRelatedToQuestion;
  }

  hints() {
    const openQuestions = this.allQuestions.filter(
      (question) => !this.solvedQuestions.includes(question),
    );

    [1, 2, 3, 4, 5].forEach(() => {
      const randomQuestion =
        openQuestions[Math.floor(Math.random() * openQuestions.length)];
      const fieldRelatedToQuestion =
        this.findFieldRelatedToQuestion(randomQuestion);

      const randomField =
        fieldRelatedToQuestion[
          Math.floor(Math.random() * fieldRelatedToQuestion.length)
        ];

      this.crosswordForm
        .get(`cell_${randomField.rowIndex}_${randomField.colIndex}`)
        ?.setValue(
          this.crosswordLayout[randomField.rowIndex][randomField.colIndex].key,
        );
    });
  }

  openWinForm() {
    this._dialog.open(XmasDialogComponent);
  }
}
