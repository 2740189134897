<h1 mat-dialog-title>You have solved the crossword!</h1>
<mat-dialog-content>
  <iframe
    width="640px"
    height="480px"
    src="https://forms.office.com/Pages/ResponsePage.aspx?id=Pq9pZ82Sm0OQWXTECbdiBL55Ap0x2OFOqlHF_7cNudZUMU9IMUFLOElUQ0pGQU45UzgxMlY0NUJONS4u&embed=true"
    frameborder="0"
    marginwidth="0"
    marginheight="0"
    allowfullscreen
    webkitallowfullscreen
    mozallowfullscreen
    msallowfullscreen
  >
  </iframe>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-dialog-close>Close</button></mat-dialog-actions
>
