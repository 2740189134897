import { AnimationEvent } from '@angular/animations';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { dsAnimations } from '@design-system/cdk/animations';
import {
  DsDrawerComponent,
  DsDrawerContentComponent,
} from '@design-system/components/drawer';
import { FreshdeskWidgetService } from '@paldesk/shared-lib/features/freshdesk-widget';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DS_FULL_PAGE_CONFIG, DsFullPageConfig } from '../config';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-full-page-header',
  standalone: false,
})
export class DsFullPageHeaderDirective {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-full-page-body',
  standalone: false,
})
export class DsFullPageBodyDirective {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ds-full-page-footer',
  standalone: false,
})
export class DsFullPageFooterDirective {
  @Input() showFreshDeskWidget = false;
}

@Component({
  selector: 'ds-full-page',
  templateUrl: './full-page.component.html',
  styleUrls: ['./full-page.component.scss'],
  animations: [
    dsAnimations.stepTransition,
    dsAnimations.slideIn,
    dsAnimations.slideOut,
  ],
  standalone: false,
})
export class DsFullPageComponent implements AfterContentInit, OnDestroy {
  @Input() disableAnimation = false;
  @HostBinding('@.disabled')
  get animationsDisabled(): boolean {
    return this.config?.disabledAnimation ?? this.disableAnimation;
  }

  @HostBinding('@dsSlideIn') dsSlideIn = '';
  @HostBinding('@dsSlideOut') dsSlideOut = '';
  @ContentChild(DsFullPageBodyDirective) pageBody: DsFullPageBodyDirective;
  @ContentChild(DsFullPageFooterDirective)
  pageFooter: DsFullPageFooterDirective;
  @ContentChild(DsDrawerContentComponent)
  drawerContent: DsDrawerContentComponent;
  @ContentChild(MatStepper) stepper: MatStepper;
  @ContentChild(DsDrawerComponent) set drawer(value: DsDrawerComponent) {
    if (value && this.stepper) value.isInStepper = true;
  }

  /** State of dialog container animation. */
  _state: 'void' | 'enter' | 'exit' = 'enter';

  animationDone = new Subject<AnimationEvent>();
  routerStepState: 'previous' | 'next' | 'current' = 'current';

  private readonly destroy$ = new Subject<void>();

  constructor(
    private freshdeskService: FreshdeskWidgetService,
    @Optional() @Inject(DS_FULL_PAGE_CONFIG) public config?: DsFullPageConfig,
  ) {}

  openFreshdeskWidget() {
    this.freshdeskService.openTheWidget();
  }

  animateStepperChange(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex < event.selectedIndex) {
      if (this.drawerContent) {
        this.drawerContent.routerStepState = 'next';
      } else {
        this.routerStepState = 'next';
      }
    } else if (event.selectedIndex < event.previouslySelectedIndex) {
      if (this.drawerContent) {
        this.drawerContent.routerStepState = 'previous';
      } else {
        this.routerStepState = 'previous';
      }
    }
  }

  ngAfterContentInit() {
    this.animationDone
      .pipe(
        // This needs a `distinctUntilChanged` in order to avoid emitting the same event twice due
        // to a bug in animations where the `.done` callback gets invoked twice on some browsers.
        // See https://github.com/angular/angular/issues/24084
        distinctUntilChanged(
          (x, y) => x.fromState === y.fromState && x.toState === y.toState,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.routerStepState = 'current';
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
