import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SafePipeModule } from '@shared-lib/safe-pipe';
import { MatDialogModule } from '@angular/material/dialog';

export interface SurveyDialogData {
  surveyUrl: string;
  additionalQueryParams?: Map<string, string>;
}

@Component({
  selector: 'ds-docu-xmas-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, SafePipeModule, MatButtonModule],
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: 'iframe { border: none; max-width: 100%; max-height: 100vh }',
})
export class XmasDialogComponent {}
