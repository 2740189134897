import { Component, ContentChild, Input, OnDestroy } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { MatStepper } from '@angular/material/stepper';
import { DsStepperSidebarComponent } from '@design-system/components/stepper-sidebar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * @deprecated
 */
@Component({
  selector: 'ds-deprecated-responsive-stepper',
  templateUrl: './responsive-stepper.component.html',
  styleUrls: ['./responsive-stepper.component.scss'],
  animations: [matExpansionAnimations.indicatorRotate],
  standalone: false,
})
export class DsResponsiveStepperComponent implements OnDestroy {
  @ContentChild(DsStepperSidebarComponent) sidebar: DsStepperSidebarComponent;

  @Input() set stepper(value: MatStepper) {
    this._stepper = value;
    if (this._stepper) {
      this._stepper.selectionChange
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this.setExpanded(false);
        });
    }
  }
  get stepper(): MatStepper {
    return this._stepper;
  }

  isExpanded = false;
  isInDrawer = false;

  private _stepper: MatStepper;

  private _destroy$ = new Subject<void>();

  setExpanded(expanded: boolean) {
    this.isExpanded = expanded;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
