<div
  class="{{ 'wrapper' + ' steps-' + stepper?.steps?.length }} full-height"
  [class.expanded]="isExpanded"
>
  <div
    class="{{ 'offset-' + stepper?.selectedIndex }} full-height"
    [class.sidebar-expanded]="!isInDrawer && sidebar?.isExpanded"
  >
    <ng-content></ng-content>
  </div>
</div>
<div class="process-indicator">
  {{ (stepper?.selectedIndex || 0) + 1 + ' / ' + stepper?.steps?.length }}
</div>
<button
  mat-icon-button
  (click)="setExpanded(!isExpanded)"
  fxFlex="0 0 auto"
  class="print-hidden stepper-toggle-button"
>
  <mat-icon [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'">
    expand_more
  </mat-icon>
</button>
