<div [fxLayoutGap]="0.5 | dsSpacing">
  <div>
    <h1>Merry Christmas and a happy new year from the Design System Team!</h1>
    <p class="no-margin">
      The Design System Chrsitmas Challenge is back! Take some time and fill out
      the crossword bellow. There will be a christmas lottery to one of the
      people who managed to solve the challenge 🎄🎅🎁
    </p>
  </div>
  <div>
    <audio controls autoplay>
      <source src="assets/music/Jingle_Bells_full.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
  <div>
    <form [formGroup]="crosswordForm">
      @for (row of crosswordLayout; track $index; let rowIndex = $index) {
        <div class="row">
          @for (cell of row; track $index; let colIndex = $index) {
            <div class="cell">
              <input
                [formControlName]="'cell_' + rowIndex + '_' + colIndex"
                maxlength="1"
                [attr.field]="'cell_' + rowIndex + '_' + colIndex"
                #input
              />
            </div>
          }
        </div>
      }
    </form>
  </div>
  <div [fxLayoutGap]="0.5 | dsSpacing">
    <p class="no-margin" [class.correct]="solvedQuestions.includes(1)">
      1. What sweet treat is shaped like a hook? 🍭
    </p>
    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(2)">
        2. Where can Santa's Service Partner view details about Santa's
        equipment?
      </p>
      <mat-icon fontSet="pd" fontIcon="pd-fleet-status"></mat-icon>
    </div>

    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(3)">
        3. Where does Rudolph file his claim?
      </p>
      <mat-icon>assignment</mat-icon>
    </div>
    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(4)">
        4. Where does Santa Claus manage access permissions for his elves?
      </p>
      <mat-icon>how_to_reg</mat-icon>
    </div>
    <p class="no-margin" [class.correct]="solvedQuestions.includes(5)">
      5. Who stole Christmas?
    </p>
    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(6)">
        6. What festive object might require lifting with a crane? 🌲
      </p>
    </div>

    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(7)">
        7. What type of PALFINGER machine could help with setting up Christmas
        lights?
      </p>
      <ds-equipment-icon type="platforms"></ds-equipment-icon>
    </div>
    <p class="no-margin" [class.correct]="solvedQuestions.includes(8)">
      8. What color is shared by both PALFINGER and Santa? 🟥
    </p>

    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(9)">
        9. Which app do the elves use when they need to buy a crane?
      </p>
      <mat-icon>trending_up</mat-icon>
    </div>
    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(10)">
        10. Which PALDESK app do people use to plan their Christmas jobs?
      </p>
      <mat-icon fontSet="pd" fontIcon="pd-palshow-web"></mat-icon>
    </div>
    <p class="no-margin" [class.correct]="solvedQuestions.includes(11)">
      11. Where does Santa live? 🐻‍❄️
    </p>
    <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
      <p class="no-margin" [class.correct]="solvedQuestions.includes(12)">
        12. Where can Santa Claus track his fleet if he owns a Connected Plus+
        contract?
      </p>
      <mat-icon fontSet="pd" fontIcon="pd-fleet-monitor"></mat-icon>
    </div>
  </div>

  <div fxLayoutAlign="start center" [fxLayoutGap]="0.25 | dsSpacing">
    <button (click)="hints()" mat-stroked-button>Hints please</button>
    @if (solvedQuestions.length === 12) {
      <button (click)="openWinForm()" mat-raised-button>Open Win form</button>
    }
  </div>
</div>
