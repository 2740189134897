import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SearchActions, SearchItem } from './store/search.actions';
import { SearchSelectors, SearchState } from './store';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';
import { Store } from '@ngrx/store';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'ds-docu-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: false,
})
export class LandingComponent implements OnDestroy {
  base$: Observable<SearchItem[]>;
  results$: Observable<string[]>;
  isLoading$: Observable<boolean>;

  searchInput = new UntypedFormControl('');
  regex = /\s|\//g;

  private _destroy$ = new Subject<void>();

  constructor(
    private _searchStore: Store<SearchState>,
    private _ga4service: GoogleAnalytics4Service,
  ) {
    this._searchStore.dispatch(SearchActions.LoadBase());
    this.base$ = this._searchStore.select(SearchSelectors.getBase);
    this.results$ = this._searchStore.select(SearchSelectors.getResult);
    this.isLoading$ = this._searchStore.select(SearchSelectors.getIsLoading);

    this.searchInput.valueChanges
      .pipe(debounceTime(500), takeUntil(this._destroy$))
      .subscribe((input) => {
        if (input.length)
          this._searchStore.dispatch(
            SearchActions.GetResults({ search: input }),
          );
        this._ga4service.event('Search Input', {
          category: 'Landing Page',
          value: input,
        });
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }
}
