<div
  fxLayout="row"
  fxLayoutAlign="space-between"
  class="container full-width"
  fxFlex="1 1 auto"
  [class.is-in-stepper]="isInStepper"
  [class.is-containing-stepper]="isContainingStepper"
  [class.is-containing-router]="isContainingRouter"
>
  <div
    fxFlex="1 1 auto"
    class="content"
    [class.with-sidebar]="!isSidebarHidden"
    #drawerContent
  >
    <ng-content select="ds-drawer-content"> </ng-content>
  </div>
  <div
    class="sidebar-wrapper"
    [style.top]="(stickyOffset$ | async) + 'px'"
    [class.closed]="!isExpanded && !isSidebarHidden"
    [hidden]="isSidebarHidden"
    #sidebarWrapper
  >
    <div
      class="sidebar"
      fxLayout="column"
      [style.max-height]="sidebarMaxHeight"
    >
      <div class="no-min-height" fxFlex="0 1 auto" fxLayout>
        <button
          mat-raised-button
          data-cy="drawer_trigger_btn"
          color="accent"
          (click)="setExpanded(!isExpanded)"
        >
          <mat-icon [@indicatorRotate]="isExpanded ? 'collapsed' : 'expanded'">
            chevron_right
          </mat-icon>
        </button>
        <mat-card
          class="full-width no-padding sidebar-content"
          [class.disable-overlay]="isSidebarDisabled"
          [class.closed]="!isExpanded && !isSidebarHidden"
        >
          <ng-content select="ds-drawer-sidebar"> </ng-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<div fxHide fxShow.lt-md>
  <div
    [@dsFadeIn]
    [@dsFadeOut]
    *ngIf="isExpanded && !isSidebarHidden"
    class="backdrop cdk-overlay-dark-backdrop print-hidden"
    [class.in-stepper-backdrop]="isInStepper"
    (click)="setExpanded(!isExpanded)"
  ></div>
</div>
