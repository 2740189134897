import { Inject, Injectable, Optional, inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DsSatisfactionFeedbackComponent } from '../satisfaction-feedback.component';
import { SatisfactionFeedbackActions } from './satisfaction-feedback.actions';
import { DS_DISABLE_SATISFACTION_SURVEY } from '../satisfaction-token';
import { UserService } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { select, Store } from '@ngrx/store';
import {
  SatisfactionFeedbackFeature,
  SatisfactionFeedbackState,
} from './satisfaction-feedback.reducers';

@Injectable()
export class FeedbackEffects {
  private _actions$ = inject(Actions);
  private _store: Store<SatisfactionFeedbackState> = inject(Store);

  ShowSatisfactionFeedback$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SatisfactionFeedbackActions.ShowSatisfactionFeedback),
        switchMap(({ data }) =>
          this._store.pipe(
            select(
              SatisfactionFeedbackFeature.shouldShowFeedback(data.featureKey),
            ),
            take(1),
            tap((shouldShow) => {
              if (shouldShow && !this._disableSurvey) {
                this._bottomSheet.open(DsSatisfactionFeedbackComponent, {
                  data,
                  autoFocus: false,
                  closeOnNavigation: true,
                  hasBackdrop: false,
                });
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  LoadUserSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SatisfactionFeedbackActions.LoadUserSettings),
      switchMap(({ key }) =>
        this._store.pipe(
          select(SatisfactionFeedbackFeature.shouldLoadUserSettings(key)),
          take(1),
          filter((shouldLoad) => shouldLoad),
          switchMap(() =>
            this._userService.getUserSetting(key, '2.0').pipe(
              map(({ value }) =>
                SatisfactionFeedbackActions.LoadUserSettingsSuccess({
                  value,
                  key,
                }),
              ),
              catchError(() =>
                of(
                  SatisfactionFeedbackActions.LoadUserSettingsFailed({
                    key,
                  }),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  SetUserSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SatisfactionFeedbackActions.SetUserSettings),
      switchMap(({ key, value }) =>
        this._userService
          .addOrUpdateUserSetting({
            settingKey: key,
            value,
          })
          .pipe(
            map(() => SatisfactionFeedbackActions.SetUserSettingsFinished()),
          ),
      ),
    ),
  );

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _userService: UserService,
    @Optional()
    @Inject(DS_DISABLE_SATISFACTION_SURVEY)
    private _disableSurvey: boolean,
  ) {}
}
